export const items = [
    {
      id: 1,
      title: "Homemade Greenhouse",
      article: "First I built the structure with some wood, sketches didn't look good but it started looking good while it was being built." +
      "<imagen/><imagen/>Once the scturcture looked better, I decided to buy some plants (coffee, stawberries, coriander, etc.). I tried using the green" +
      " net but it didn't happen to be a good idea as it didn't  protect the plants from the sun. I also made some shelfs and I also bought a tray"+
      " to germinate some seeds.<imagen/><imagen/><imagen/>The greenhouse needed a roof to protect the plants from rain and sun so I built it using" +
      " polycarbonate and I changed the green net for a shade net .<imagen/><imagen/>So once it was all done. I went to buy more plants. " +
      "<imagen/><imagen/><imagen/>",
      image: [ require('../../images/diy/DIY01.JPG').default, require('../../images/diy/DIY02.JPG').default, require('../../images/diy/DIY03.JPG').default, 
      require('../../images/diy/DIY04.JPG').default, require('../../images/diy/DIY05.JPG').default, require('../../images/diy/DIY06.JPG').default, 
      require('../../images/diy/DIY07.JPG').default, require('../../images/diy/DIY08.JPG').default, require('../../images/diy/DIY09.JPG').default,
      require('../../images/diy/DIY10.JPG').default ]
    }
    // {
    //   id: 2,
    //   article: "How to",
    //   title: "Arrange Your Apple Devices for the Gram"
    // },
    // {
    //   id: 3,
    //   article: "Pedal Power",
    //   title: "Map Apps for the Superior Mode of Transport"
    // }
  ];
  