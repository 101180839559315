import React from 'react'
import { HomeScreen, HomeTitle, HomeContent, HomeInfo } from '../Home/HomeElements'
import { pingUser } from '../PingInfo'

const tagVariants = {
  show: {
    opacity: 1,
    transition: {
      duration: 1.2,
      ease: [0.83, 0, 0.17, 1]
    }
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 1.2,
      ease: [0.83, 0, 0.17, 1]
    }
  }
};

const HomeSection = () => {
  pingUser()
  return (
      <>
      <HomeScreen>
          <HomeInfo
            variants= { tagVariants }
            initial= "hidden"
            animate= "show">
          <HomeTitle>Welcome to my greenhouse!</HomeTitle>
          <HomeContent>
              In this site you will be able to chat with other people about gardening, greenhouse building, plant care & read some articles about sustainability.
          </HomeContent>
          </HomeInfo>
      </HomeScreen>
      </>
  )
}

export default HomeSection
