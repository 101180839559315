import React from 'react'
import {PlantsScreen, PlantsContainer, PlantContainer, PlantsLineBreak, PlantsSpace, Plant, PlantBG, PlantName } from './PlantsElements'
import { DarkBG, Close, CloseButton, Construnction } from '../MainElements'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { items } from "./data"
import { pingUser } from '../PingInfo'

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96]}
const tagVariants = {
  show: {
    opacity: 1,
    transition: {
      duration: 1.2,
      ease: [0.5, 0, 0.17, 1]
    }
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 1.2,
      ease: [0.83, 0, 0.17, 1]
    }
  },
  articleShow: {
    opacity: 1,
    transition: {
      delay: 1,
      duration: 1.2,
      ease: [0.5, 0, 0.17, 1]
    }
  },
  articleHidden: {
    opacity: 0,
    transition: {
      delay: 1,
      duration: 1.2,
      ease: [0.83, 0, 0.17, 1]
    }
  },
  bgShow: {
      opacity: 1,
      transition: {
        ease: [0.83, 0, 0.17, 1]
      }
    },
  bgHidden: {
      opacity: 0,
      transition: {
        ease: [0.83, 0, 0.17, 1]
      }
  }
};
  

const PlantsSection = () => {
  pingUser()
  return (
  <>
  <BrowserRouter>
    <PlantsScreen
      variants= { tagVariants }
      initial= "hidden"
      animate= "show">
      <PlantsContainer>
      {items.map(plant => (
        <PlantOverview {...plant} />
      ))}
      </PlantsContainer>
    </PlantsScreen>
      <Switch>
      <Route exact path="/plant/:plantId" component={ renderPlant } />
      </Switch>
  </BrowserRouter>
  </>
  )
}

export class renderPlant extends React.Component{
  render()
  {
    return (
      <>
      <DarkBG>
      <PlantBG 
        variants= { tagVariants }
        initial= "bgHidden"
        animate= "bgShow">
          <Close to="/articles">
            <CloseButton
              variants= { tagVariants }
              initial= "hidden"
              animate= "show"
              whileHover={{ scale: 1.5 }} transition={ transition }>X
            </CloseButton>
          </Close>
          <Construnction>Sorry, I'm still developing this section 😔.</Construnction>
      </PlantBG>
      </DarkBG>
      </>
    )
  }
}
  
function PlantOverview({ id, title, path }) {
  // const images = require.context('../../images/plants', true);
  // const baseUrl = "../../images/plants/";
  return (
  <>
    {/* <PlantLink className="navbar-item" activeClassName="is-active" to={`/plant/${id}`}> */}
      {/* <PlantContainer  whileHover={{ scale: 1.1 }} transition={ transition }> */}
      <PlantContainer
          variants= { tagVariants }
          initial= "bgHidden"
          animate= "bgShow">
          {/* <Plant src= {require('../../images/plants/jalapeno.JPG').default} /> */}
          {/* <Plant src= {require(path).default} /> */}
          <Plant src={ path } />
          <PlantName>{ title }</PlantName>
      </PlantContainer>
    {/* </PlantLink> */}
    {id % 2 === 0 ? (
        <PlantsLineBreak>&nbsp;</PlantsLineBreak>
      ) : (
        <PlantsSpace>&nbsp;</PlantsSpace>
      )}
  </>
  );
}

export default PlantsSection
