import React from 'react'
import { DarkBG, TextBG, Close, CloseButton, Construnction } from '../MainElements'
import { pingUser } from '../PingInfo'

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96]}
export const data = [
    {
      title: "Home",
      id: "home",
      url: "/"
    },
    {
      title: "case1",
      id: "case1",
      url: "/case1"
    },
    {
      title: "case2",
      id: "case2",
      url: "/case2"
    },
    {
      title: "case3",
      id: "case3",
      url: "/case3"
    }
  ];

  const tagVariants = {
    show: {
      opacity: 1,
      transition: {
        duration: 1.2,
        ease: [0.5, 0, 0.17, 1]
      }
    },
    hidden: {
      opacity: 0,
      transition: {
        duration: 1.2,
        ease: [0.83, 0, 0.17, 1]
      }
    }
  };
  
const NotFoundSection = () => {
  pingUser()
  return (
    <>
      {/* Erase this code once it's developed */}
      <DarkBG>
        <TextBG 
          variants= { tagVariants }
          initial= "bgHidden"
          animate= "bgShow">
            <Close to="/">
              <CloseButton
                variants= { tagVariants }
                initial= "hidden"
                animate= "show"
                whileHover={{ scale: 1.5 }} transition={ transition }>X
              </CloseButton>
            </Close>
            <Construnction>Sorry, this page does not exist.</Construnction>
        </TextBG>
        </DarkBG>
    </>
  )
}

export default NotFoundSection
