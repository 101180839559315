import styled from 'styled-components'
import { motion } from 'framer-motion'
import { NavLink } from "react-router-dom"

export const AppContainer = styled.div `
    height: 100%;
    width: 100%;
    background: #eef6ec;
    overflow-y: auto;
    position: fixed;
`;

export const MainFooter = styled.div `
    background: #519839;
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
    opacity: 0.5;
    vertical-align: middle;
    position: fixed;
    text-align: center;
    z-index: 11;
    bottom: 0;
    height: 3%;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (max-width: 812px) and (orientation : landscape)
    {
        width: 80%;
        height: 12%;
    }
`;

export const FooterText = styled.p `
    color: #fff;
    position: absolute;
    font-size: 1vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        font-size: 3vw;
    }
    @media screen and (max-width: 812px) and (orientation : landscape)
    {
        font-size: 2vw;
        top: 30%;
    }
`;

export const FooterLink = styled.a `
    color: #fff;
    font-weight: bolder;
    cursor: pointer;
    &:hover {
        color: #000;
        text-decoration: none;
      }
    &:active{
        border-bottom: 3px solid #01bf71;
        text-decoration: none;
    }
`;


export const DarkBG = styled(motion.div) `
    background: rgba(81, 152, 57, 0.8);
    left: 0;
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow: auto;
`;

export const ErrorBG = styled(motion.div) `
    background: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow: auto;
`;

export const TextBG = styled(motion.div) `
    background: #B6CFB6;
    position: fixed;
    width: 30%;
    height: 20%;
    z-index: 100;
    overflow: auto;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 3rem;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        width: 50%;
        border-radius: 1rem;
    }
    @media screen and (max-width: 812px) and (orientation : landscape)
    {
        width: 30%;
        height: 40%;
        border-radius: 1rem;
    }
`;

export const CloseButton = styled(motion.div) `
    background: rgb(170, 86, 86);
    position: fixed;
    color: rgb(53, 53, 53);
    right: 7%;
    top: 7%;
    width: 3rem;
    height: 3rem;
    z-index: 102;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2vw;
    font-weight: bold;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        width: 1.5rem;
        height: 1.5rem;
        font-size: 3vw;
    }
    @media screen and (max-width: 812px) and (orientation : landscape)
    {
        width: 1.5rem;
        height: 1.5rem;
        font-size: 2vw;
    }
`;

export const Close = styled(NavLink)`
    cursor: pointer;
`;

export const Construnction = styled.p `
    font-size: 1.8vw;
    color: black;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: justify;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        font-size: 3.5vw;
    }
    @media screen and (max-width: 812px) and (orientation : landscape)
    {
        font-size: 2.5vw;
    }
`;