import styled from 'styled-components'
import { motion } from 'framer-motion'
import { NavLink } from "react-router-dom";

export const PlantsScreen = styled.div `
    left: 5%;
    position: absolute;
    width: 90%;
    padding-bottom: 2%;
    padding-top: 4%;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        padding-top: 12%;
    }
    @media screen and (max-width: 812px) and (orientation : landscape)
    {
        padding-top: 8%;
    }
`;

export const PlantsContainer = styled.ul `
    padding-left: 1%;
    padding-top: 1%;
    z-index: -1;
`;

export const PlantLink = styled(NavLink)`
    cursor: context-menu;
    &:hover {
        text-decoration: none;
      }
    &:active{
        text-decoration: none;
    }
`;

export const PlantContainer = styled(motion.li) `
    background: #B6CFB6;
    box-shadow: -0.4rem 0.5rem 0.2rem 0.8rem rgba(0, 0, 0, 0.5), 0 0 0 0 rgba(0, 0, 0, 1);
    width: 40%;
    float: left;
    list-style-type: none;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    position: relative;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        box-shadow: 0.1rem 0.5rem 0.1rem 0.2rem rgba(0, 0, 0, 0.5), 0 0 0 0 rgba(0, 0, 0, 1);
        border-radius: 2rem;
    } 
    @media screen and (max-width: 812px) and (orientation : landscape)
    {
        box-shadow: 0.1rem 0.5rem 0.1rem 0.2rem rgba(0, 0, 0, 0.5), 0 0 0 0 rgba(0, 0, 0, 1);
        border-radius: 2rem;
    }
`;

export const PlantName = styled.p `
    background:  rgba(81, 152, 57, 0.6);
    color: white;
    width: 20rem;
    float: left;
    position: absolute;
    right: 2rem;
    bottom: 1rem;
    padding: 5px;
    font-weight: bold;
    z-index: 100;
    font-size: 3rem;
    text-align: center;
    border-radius: 3rem;
    cursor: context-menu;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        width: 5.2rem;
        font-size: 3vw;
        border-radius: 1rem;
        bottom: 0.1rem;
        right: 0.5rem;
    }
    @media screen and (max-width: 812px) and (orientation : landscape)
    {
        width: 10rem;
        font-size: 3vw;
        border-radius: 1rem;
        bottom: 0.1rem;
        right: 0.5rem;
    }
`;

// export const Plant = styled.p `
//     font-size: 2vw;
//     color: black;
// `;

export const Plant = styled.img `
    border-radius: 3rem;
    width: 100%;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        border-radius: 2rem;
    }
    @media screen and (max-width: 812px) and (orientation : landscape)
    {
        border-radius: 2rem;
    }
`;

export const PlantsSpace = styled.li `
    list-style-type: none;
    float: left;
    width: 19%;
`;

export const PlantsLineBreak = styled.li `
    list-style-type: none;
    float: left;
    width: 99%;
    font-size: 2vw;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        font-size: 5vw;
    }
    @media screen and (max-width: 812px) and (orientation : landscape)
    {
        font-size: 5vw;
    }
`;

export const DarkBG = styled(motion.div) `
    background: rgba(81, 152, 57, 0.8);
    left: 0;
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow: auto;
`;

export const PlantBG = styled(motion.div) `
    background: #B6CFB6;
    position: fixed;
    width: 30%;
    height: 20%;
    z-index: 100;
    overflow: auto;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 3rem;
`;