import styled from 'styled-components'
import {motion} from 'framer-motion'

export const HomeScreen = styled.div`
    left: 5%;
    position: absolute;
    width: 90%;
    padding-bottom: 2%;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 4%;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        padding-top: 12%;
    }
`;

export const HomeInfo = styled(motion.div)`
    // background: purple;
`;

export const HomeTitle = styled(motion.div)`
    // background: #7dba87;
    text-shadow: -0.2rem 0.2rem rgba(0, 0, 0, 0.5);
    top: 5%;
    text-align: center;
    font-size: 6vw;
    line-height: 6vw;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        text-shadow: -0.1rem 0.1rem rgba(0, 0, 0, 0.5);
    }
`;

export const HomeContent = styled(motion.div) `
    padding-top: 2%;
    top: 5%;
    position: relative;
    text-align: justify;
    height: 100%;
    font-size: 3vw;
    @media screen and (max-width: 780px) and (orientation : portrait) {
        font-size: 7vw;
        height: 70%;
    }
`;