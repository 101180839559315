import React from 'react'
import { ArticlesScreen, ArticlesContainer, ArticleContainer, ArticlesSpace, ArticlesLineBreak, ArticleTitle,
         ArticleLink, ArticleBox, ArticleBG, CloseButton, Close, ArticleHeader, ArticleDescription, ArticleImage } from './ArticlesElements'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { ErrorBG, TextBG, Construnction } from '../MainElements'
import { items } from "./data";
import { pingUser } from '../PingInfo'

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96]}
const tagVariants = {
  show: {
    opacity: 1,
    transition: {
      duration: 1.2,
      ease: [0.5, 0, 0.17, 1]
    }
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 1.2,
      ease: [0.83, 0, 0.17, 1]
    }
  },
  articleShow: {
    opacity: 1,
    transition: {
      delay: 1,
      duration: 1.2,
      ease: [0.5, 0, 0.17, 1]
    }
  },
  articleHidden: {
    opacity: 0,
    transition: {
      delay: 1,
      duration: 1.2,
      ease: [0.83, 0, 0.17, 1]
    }
  },
  bgShow: {
      opacity: 1,
      transition: {
        duration: 1,
        ease: [0.83, 0, 0.17, 1]
      }
    },
  bgHidden: {
      opacity: 0,
      transition: {
        duration: 1,
        ease: [0.83, 0, 0.17, 1]
      }
  }
};
  
function Artic({ id, title }) {
  return (
  <>
    <ArticleLink className="navbar-item" activeClassName="is-active" to={`/articles/${id}`}>
      <ArticleContainer  whileHover={{ scale: 1.1 }} transition={ transition }>
          <ArticleTitle dangerouslySetInnerHTML={{__html: title}}/>
            {/* { title.split('\n').map(str => <p>{str}</p>)} and  {id} and {id % 2}</ArticleTitle> */}
      </ArticleContainer>
    </ArticleLink>
    {id % 2 === 0 ? (
        <ArticlesLineBreak>&nbsp;</ArticlesLineBreak>
      ) : (
        <ArticlesSpace>&nbsp;</ArticlesSpace>
      )}
  </>
  );
}

const ArticlesSection = () => {
  pingUser()
  return (
    <>
    <BrowserRouter>
      <ArticlesScreen
        variants= { tagVariants }
        initial= "hidden"
        animate= "show">
        <ArticlesContainer>
        {items.map(article => (
          <Artic {...article} />
        ))}
        </ArticlesContainer>
      </ArticlesScreen>
        <Switch>
        <Route exact path="/articles/:articleId" component={ renderArticle } />
        </Switch>
    </BrowserRouter>
    </>
  )
}

export class renderArticle extends React.Component{
  render()
  {
    pingUser()
    const article = items.find((h) => h.id === parseInt(this.props.match.params.articleId))
    return (
      <>
      { article ?
        <ArticleBG 
          variants= { tagVariants }
          initial= "bgHidden"
          animate= "bgShow">
            <Close to="/articles">
              <CloseButton
                variants= { tagVariants }
                initial= "hidden"
                animate= "show"
                whileHover={{ scale: 1.5 }} transition={ transition }>X
              </CloseButton>
            </Close>
            <ArticleBox
                variants= { tagVariants }
                initial= "articleHidden"
                animate= "articleShow">
              <ArticleHeader dangerouslySetInnerHTML={{__html: article.title}} />
              { 
                article.article.includes("<imagen/>") ? 
                  article.article.split("<imagen/>").map((content, i) => {  
                    return(content === "" ?  i === article.article.split("<imagen/>").length - 1 ? null : <ArticleImage  src= { article.image[i] }/> : 
                      [<ArticleDescription  dangerouslySetInnerHTML={{ __html: content }}/>, i === article.article.split("<imagen/>").length - 1 ? null : 
                      <ArticleImage  src= { article.image[i] } />])}) :
                  <ArticleDescription  dangerouslySetInnerHTML={{__html: article.article}}/>
              }
            </ArticleBox>
        </ArticleBG>
        : 
        <ErrorBG>
          <TextBG variants= { tagVariants } initial= "bgHidden" animate= "bgShow">
            <Close to="/articles">
              <CloseButton variants= { tagVariants } initial= "hidden" animate= "show" whileHover={{ scale: 1.5 }} transition={ transition }>X
              </CloseButton>
            </Close>
            <Construnction>Something went wrong with this article.</Construnction>
          </TextBG>
        </ErrorBG>
      }
      </>
    )
  }
}


export default ArticlesSection
