import React from 'react'
// import {FaBars} from 'react-icons/fa'
import {
    Nav, 
    NavBarContainer, 
    NavLogo, 
    // MobileIcon,
    NavBarBlock,
    NavLinks,
    Blank,
    PageLogo
} from './NavBarElements'
import logo from '../../images/logo.png'
// import jal from '../../images/plants/jalapeno.JPG'

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96]}

const NavBar = ({toggle}) => {
    return (
        <>
            <Blank>
                <Nav>
                    <NavBarContainer>
                        <NavLogo className="navbar-item" activeClassName="is-active" to="/">
                        <NavBarBlock whileHover={{ scale: 1.5 }} transition={ transition }>
                            {/* <PageLogo src= {require('../../images/logo.png').default} /> */}
                            <PageLogo src= {logo} />
                        </NavBarBlock>
                        </NavLogo>
                        {/* <MobileIcon onClick={toggle}>
                            <FaBars />
                        </MobileIcon> */}
                        <NavLinks className="navbar-item" activeClassName="is-active" to="/">
                        <NavBarBlock whileHover={{ scale: 1.5}} transition={ transition }>
                            Home
                        </NavBarBlock>
                        </NavLinks>
                            <NavLinks className="navbar-item" activeClassName="is-active" to="/articles">
                        <NavBarBlock whileHover={{ scale: 1.5 }} transition={ transition }>Articles
                        </NavBarBlock>
                        </NavLinks>
                            <NavLinks className="navbar-item" activeClassName="is-active" to="/plants">
                        <NavBarBlock whileHover={{ scale: 1.5 }} transition={ transition }>Urban jungle
                        </NavBarBlock>
                        </NavLinks>
                        <NavLinks className="navbar-item" activeClassName="is-active" to="/blog">
                        <NavBarBlock whileHover={{ scale: 1.5 }} transition={ transition }>Blog
                        </NavBarBlock>
                        </NavLinks>
                    </NavBarContainer>
                </Nav>
            </Blank>
        </>
    )
}

export default NavBar
