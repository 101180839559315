import styled from 'styled-components'
import {motion} from 'framer-motion'

export const BlogScreen = styled(motion.div) `
    left: 5%;
    position: absolute;
    width: 90%;
    padding-bottom: 2%;
    padding-top: 4%;
`;

export const BlogContainer = styled.ul `
    padding-left: 1%;
    padding-top: 1%;
    z-index: -1;
`;