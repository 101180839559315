import styled from 'styled-components'
import { motion } from 'framer-motion'
import { NavLink } from "react-router-dom";

export const ArticlesScreen = styled(motion.div) `
    left: 5%;
    position: absolute;
    width: 90%;
    padding-bottom: 2%;
    padding-top: 4%;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        padding-top: 12%;
    }
`;

export const ArticlesContainer = styled.ul `
    padding-left: 1%;
    padding-top: 1%;
    z-index: -1;
`;
//B6CFB6
//016E6E
//4885ed
export const ArticleContainer = styled(motion.li) `
    background: #B6CFB6;
    box-shadow: -0.5rem 0.5rem 0.2rem 0.5rem rgba(0, 0, 0, 0.5), 0 0 0 0 rgba(0, 0, 0, 1);
    width: 40%;
    float: left;
    list-style-type: none;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    padding-left: 1%;
    padding-top: 1%;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        box-shadow: -0.2rem 0.3rem 0.1rem 0.1rem rgba(0, 0, 0, 0.5), 0 0 0 0 rgba(0, 0, 0, 1);
    }
`;

export const ArticlesSpace = styled.li `
    list-style-type: none;
    float: left;
    width: 19%;
`;

export const ArticlesLineBreak = styled.li `
    list-style-type: none;
    float: left;
    width: 99%;
    font-size: 2vw;
`;

export const ArticleTitle = styled.p `
    font-size: 2vw;
    color: black;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        padding-left: 10%;
        padding-top: 2%;
        font-size: 3vw;
    }
`;

export const ArticleLink = styled(NavLink)`
    // color: #fff;
    // width: 100%;    
    // display: block;
    // text-align: center;
    // font-size: 0.8vw;
    // text-decoration: none;
    // padding: 0 1rem;
    cursor: pointer;
    &:hover {
        // color: #fff;
        text-decoration: none;
      }
    &:active{
        // border-bottom: 3px solid #01bf71;
        text-decoration: none;
    }
`;

export const ArticleImage = styled.img `
    border-radius: 3rem;
    width: 30rem;
    margin-right: 3rem;
    margin-bottom: 3rem;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        width: 90%;
        border-radius: 1rem;
        margin-right: 0;
        margin-left: 5%;
    }
    @media screen and (max-width: 812px) and (orientation : landscape)
    {
        width: 40%;
        border-radius: 1rem;
        margin-right: 5%;
        margin-left: 5%;
    }
`;

export const ArticleBG = styled(motion.div) `
    background: #B6CFB6;
    left: 0;
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow: auto;
`;

export const ArticleBox = styled(motion.div) `
    background: #eef6ec;
    position: absolute;
    border-radius: 3rem;
    left: 1%;
    top: 1%;
    width: 98%;
    z-index: 101;
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
    overflow: auto;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        border-radius: 1rem;
    }
    @media screen and (max-width: 812px) and (orientation : landscape)
    {
        border-radius: 1rem;
        left: 5%;
        width: 90%;
    }
`;

export const CloseButton = styled(motion.div) `
    background: rgb(170, 86, 86);
    position: fixed;
    color: rgb(53, 53, 53);
    right: 3%;
    top: 3%;
    width: 3rem;
    height: 3rem;
    z-index: 102;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2vw;
    font-weight: bold;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        right: 2%;
        top: 2%;
        font-size: 5vw;
        width: 2rem;
        height: 2rem;
    }
    @media screen and (max-width: 812px) and (orientation : landscape)
    {
        right: 6%;
        top: 2%;
        font-size: 2.5vw;
        width: 2rem;
        height: 2rem;
    }
`;

export const Close = styled(NavLink)`
    cursor: pointer;
`;

export const ArticleHeader = styled.p `
    font-size: 3vw;
    color: black;
    font-weight: bold;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        font-size: 5vw;
    }
    @media screen and (max-width: 812px) and (orientation : landscape)
    {
        font-size: 4.5vw;
    }
`;

export const ArticleDescription = styled.p `
    font-size: 1.8vw;
    color: black;
    text-align: justify;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        font-size: 4vw;
    }
    @media screen and (max-width: 812px) and (orientation : landscape)
    {
        font-size: 3.5vw;
    }
`;