import React from 'react'
import { BlogScreen, BlogContainer } from './BlogElements'
import { DarkBG, TextBG, Close, CloseButton, Construnction } from '../MainElements'
import { pingUser } from '../PingInfo'
// import { AnimateSharedLayout } from "framer-motion";
// import { BrowserRouter as Router } from "react-router-dom";
// import Case from "./Case";

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96]}
export const data = [
    {
      title: "Home",
      id: "home",
      url: "/"
    },
    {
      title: "case1",
      id: "case1",
      url: "/case1"
    },
    {
      title: "case2",
      id: "case2",
      url: "/case2"
    },
    {
      title: "case3",
      id: "case3",
      url: "/case3"
    }
  ];

  const tagVariants = {
    show: {
      opacity: 1,
      transition: {
        duration: 1.2,
        ease: [0.5, 0, 0.17, 1]
      }
    },
    hidden: {
      opacity: 0,
      transition: {
        duration: 1.2,
        ease: [0.83, 0, 0.17, 1]
      }
    }
  };
  
const BlogSection = () => {
  pingUser()
  return (
      <>
          {/* Erase this code once it's developed */}
          <DarkBG>
            <TextBG 
              variants= { tagVariants }
              initial= "bgHidden"
              animate= "bgShow">
                <Close to="/">
                  <CloseButton
                    variants= { tagVariants }
                    initial= "hidden"
                    animate= "show"
                    whileHover={{ scale: 1.5 }} transition={ transition }>X
                  </CloseButton>
                </Close>
                <Construnction>Sorry, I'm still developing this section 😔.</Construnction>
            </TextBG>
            </DarkBG>

          <BlogScreen
            variants= { tagVariants }
            initial= "hidden"
            animate= "show">
            <BlogContainer>
              {/* <h1>Blog</h1> */}
              {/* <Router>
              <AnimateSharedLayout>
                  <nav
                  style={{
                      height: "100vh",
                      width: "100%",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#ffff00"
                  }}
                  >
                  <Case />
                  </nav>
              </AnimateSharedLayout>
              </Router> */}
              </BlogContainer>
          </BlogScreen>
      </>
  )
}

export default BlogSection
