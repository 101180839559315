import './App.css';
import MainPage from './pages';
import {MainFooter, FooterText, FooterLink, AppContainer} from './components/MainElements'

function App() {
  return (
    <AppContainer>
      <MainPage />
      <MainFooter><FooterText>Created by&nbsp;<FooterLink href="https://hecdelarosa.com">hecdelarosa</FooterLink></FooterText></MainFooter>
    </AppContainer>
  );
}

export default App;
