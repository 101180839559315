import styled from 'styled-components'
import { NavLink } from "react-router-dom";
import {motion} from 'framer-motion'

export const Blank = styled.nav `
    background: #eef6ec;
    position: fixed;
    z-index: 9;
    height: 8%;
    width: 100%;
`;

export const Nav = styled.nav `
    background: rgba(81, 152, 57, 0.8);
    box-shadow: -0.5rem 0.3rem 0rem 0.2rem rgba(0, 0, 0, 0.4), 0 0 0 0 rgba(0, 0, 0, 1);
    border-radius: 3rem;
    margin: 0;
    top: 2%;
    position: fixed;
    z-index: 10;
    height: 6%;
    width: 95%;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        height: 3%;
        box-shadow: -0.1rem 0rem 0rem 0.1rem rgba(0, 0, 0, 0.4), 0 0 0 0 rgba(0, 0, 0, 1);
    }
    @media screen and (max-width: 812px) and (orientation : landscape)
    {
        width: 90%;
        height: 8%;
        box-shadow: -0.1rem 0rem 0rem 0.1rem rgba(0, 0, 0, 0.4), 0 0 0 0 rgba(0, 0, 0, 1);
    }
`;

export const MobileIcon = styled.div `
    display: none;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        display: block;
        position: absloute;
        top: 0;
        right: 0;
        transform: translate(-100%, 20%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    } 
`;

export const NavBarContainer = styled.ul `
    width: 90%;
    height: 85%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
`;
export const NavBarBlock = styled(motion.li) `
    // background: purple;
    width: 20%;
    float: left;
    list-style-type: none;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        font-size: 2vw;
    }
    @media screen and (max-width: 812px) and (orientation : landscape)
    {
        font-size: 1.8vw;
    }
`;

export const PageLogo = styled.img`
    height: 3rem;
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        height: 1.5rem;
    }
    @media screen and (max-width: 812px) and (orientation : landscape)
    {
        height: 2rem;
    }
`;

export const NavLogo = styled(NavLink)`
    color: #fff;
    font-size: 1.2vw;
    width: 100%;
    display: block;
    text-align: center;
    text-decoration: none;
    padding: 0 1rem;
    cursor: pointer;
    &:hover {
        color: #fff;
        text-decoration: none;
      }
    &:active{
        border-bottom: 3px solid #01bf71;
        text-decoration: none;
    }
    @media screen and (max-width: 768px) and (orientation : portrait) 
    {
        margin-left: -20%;
    }
`;

export const NavLinks = styled(NavLink)`
    color: #fff;
    width: 100%;    
    display: block;
    text-align: center;
    font-size: 0.8vw;
    text-decoration: none;
    padding: 0 1rem;
    cursor: pointer;
    &:hover {
        color: #fff;
        text-decoration: none;
      }
    &:active{
        border-bottom: 3px solid #01bf71;
        text-decoration: none;
    }
`;
