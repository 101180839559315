import React from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import NavBar from '../components/NavBar'
import HomeSection from '../components/Home'
import PlantsSection from '../components/Plants'
import ArticlesSection from '../components/Articles'
import BlogSection from '../components/Blog'
import NotFoundSection from '../components/NotFound'
import {AnimatePresence} from 'framer-motion'

const MainPage = () => {

    return (
        <>
        <BrowserRouter>
          <NavBar />
          <div className="container mt-2" style={{ marginTop: 40 }}>
            <AnimatePresence exitBeforeEnter>
            <Switch>
              <Route exact path="/">
                <HomeSection />
              </Route>
              <Route path="/articles">
                <ArticlesSection />
              </Route>
              <Route path="/plants">
                <PlantsSection />
              </Route>
              <Route path="/blog">
                <BlogSection />
              </Route>
              <Route path='*' exact={true} component={NotFoundSection} />
            </Switch>
            </AnimatePresence>
          </div>
        </BrowserRouter>
        </>
    )
}

export default MainPage
