export function pingUser()
{
  window.myGlobalVar = false
  let userInf = ''
  const publicIp = require('public-ip');
  (async () => {
    try
    {
      const ipv4 = await publicIp.v4()
      if(ipv4 && ipv4 !== "" && !window.myGlobalVar)
      {
        userInf = Buffer.from('13'+Buffer.from(ipv4).toString('base64')).toString('base64')
      }
    }
    catch (error) {
      try
      {
        const ipv6 = await publicIp.v6()
        if(ipv6 && ipv6 !== "" && !window.myGlobalVar)
        {
          userInf = Buffer.from('13'+Buffer.from(ipv6).toString('base64')).toString('base64')
        }
      }
      catch (error) {
        console.log(error)
      }
    }
    if(userInf && userInf !== "" && !window.myGlobalVar)
    {
      window.myGlobalVar = true
      const userPage = Buffer.from('13'+Buffer.from(window.location.href).toString('base64')).toString('base64')
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data01: userInf, data02: userPage })
      };
      fetch('https://greenservices.hecdelarosa.com/green/UserPing', requestOptions);
    }
  })();
}