export const items = [
    {
      id: 1,
      title: "Jalapeno Pepper",
      path: require('../../images/plants/jalapeno.JPG').default
    },
    {
      id: 2,
      title: "Tomatos",
      path: require('../../images/plants/tomato.JPG').default
    },
    {
      id: 3,
      title: "Strawberries",
      path: require('../../images/plants/strawberries.JPG').default
    }
  ];
  